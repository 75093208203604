// src/schemas/pharmacySchema.ts
import { z } from 'zod';

export const pharmacySchema = z.object({
  phone: z.string(),
  name: z.string().max(255, 'Name must be at most 255 characters'),
  npi_number: z.string().optional(),
  icon_url: z.string().optional(),
  timezone: z.string().nonempty('Timezone is required'),
  pharmacy_group_id: z.string().optional(),
  open_time: z.string().nonempty('Open Time is required').default('9:00 AM'),
  close_time: z.string().nonempty('Close Time is required').default('5:00 PM'),
  open_weekend_time: z.string().nonempty('Open Weekend Time is required').default('9:00 AM'),
  closed_weekend_time: z.string().nonempty('Closed Weekend Time is required').default('3:00 PM'),
  open_weekends: z.boolean().default(true),
  do_not_ai_call: z.boolean().default(false),
  invalid_pharmacy: z.boolean().default(false),
  cutoff_time: z.string().nonempty('Cutoff Time is required').default('3:00 PM'),
  lunch_start_time: z.string().nonempty('Lunch Start Time is required').default('1:30 PM'),
  lunch_end_time: z.string().nonempty('Lunch End Time is required').default('2:00 PM'),
});
